<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-3">
        <h1 class="display-2 font-weight-bold mb-3 mt-10">
          Progressive Overload Calculator
        </h1>

        <p class="subheading font-weight-regular">
          Scientifically proven method to increase strength and performance
        </p>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
      <v-text-field
            label="Exercise Name"
            v-model="name"
            placeholder="ex. Bench Press"
            outlined
          ></v-text-field>
      </v-col>
      <v-row class="justify-center">
      <v-col
        class="mb-3 mr-5"
        cols="5"
      >
      <v-text-field
            label="One Rep Max"
            placeholder="ex. 225"
            suffix="lbs"
            outlined
            v-model="maxPR"
          ></v-text-field>
      </v-col>
      <v-text class="mt-6 mx-16">
      or
      </v-text>
          <v-col
        class="mb-3 ml-5"
        cols="5"
      >
      <v-text-field
            label="Starting Rep Weight"
            placeholder="ex. 185"
            suffix="lbs"
            outlined
            v-model="repWeight"
          ></v-text-field>
      </v-col>
      </v-row>
      <v-col
        class="mb-3"
        cols="12"
      >
      <v-subheader class="mb-4">Exercise frequency per week</v-subheader>
         <v-slider
        v-model="frequency"
        step="1"
        thumb-label="always"
        ticks
        min="1"
        max="7"
        default="2"
      ></v-slider>
      </v-col>
      <v-col
        class="mb-3"
        cols="12"
      >
      <v-subheader class="mb-4">Sets per day</v-subheader>
         <v-slider
        v-model="sets"
        step="1"
        thumb-label="always"
        ticks
        min="1"
        max="10"
      ></v-slider>
      </v-col>
            <v-col
        class="mb-3"
        cols="12"
      >
      <v-subheader class="mb-4">Reps per set goal</v-subheader>
         <v-slider
        v-model="reps"
        step="1"
        thumb-label="always"
        ticks
        min="1"
        max="16"
      ></v-slider>
      </v-col>
      <v-col
      class="mb-3"
        cols="12">
         <v-subheader class="mb-4 text-sm-center justify-center">Weekly Increments</v-subheader>
        <v-btn-toggle
          v-model="weeklyIncrement"
          rounded
          mandatory
        >
          <v-btn value="5">
            5lbs
          </v-btn>
          <v-btn value="10">
            10lbs
          </v-btn>
          <v-btn value="15">
            15lbs
          </v-btn>
          <v-btn value="20">
            20lbs
          </v-btn>
        </v-btn-toggle>
        <v-row class="d-flex justify-end mr-5">
        <v-checkbox label="Warm-up" v-model="warmupChecked">
        </v-checkbox>
         </v-row>
      </v-col>
      <v-col>
        <v-card>
          <h2 class="text-sm-left ml-3 py-3">{{ name }}
          </h2>
          <v-row class="justify-center">
            <v-card v-for="week in weeks" :key="week" class="px-3 py-4 mx-2" elevation="0">
              <h3 class="pb-3">Week {{ week }}</h3>
          <v-card elevation="1" v-for="freq in frequency" :key="freq" class="px-4 py-4">
            <h3 class="text-sm-left mx-3 py-3">Day {{ freq }}</h3>
            <div v-if="warmupChecked">
            <h4 class="text-sm-left mx-3 py-3">
              Warm-up: 5 reps of {{ warmupRepScheme[0] }}lbs</h4>
            <h4 class="text-sm-left mx-3 py-3">
              Warm-up: 3 reps of {{ warmupRepScheme[1] }}lbs</h4>
            <h4 class="text-sm-left mx-3 py-3">
              Warm-up: 1 rep of {{ warmupRepScheme[2] }}lbs</h4>
            </div>
            <h4 class="text-sm-left mx-3 py-3" v-for="set in sets" :key="set">Set {{ set }}:
              {{ repScheme[freq-1][set-1] }} reps of {{ repWeightIncrements[week-1] }}lbs</h4>
          </v-card>
            </v-card>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',

  data: () => ({
    name: '',
    frequency: 2,
    sets: 5,
    reps: 5,
    weeks: 4,
    repWeight: 0,
    maxPR: 0,
    weeklyIncrement: 5,
    warmupSets: 3,
    warmupChecked: false,
  }),
  computed: {
    repScheme() {
      const repArray = [];
      for (let index = 0; index < this.frequency; index += 1) {
        const tempRepArray = [];
        let decriment = -1;
        for (let index2 = 0; index2 < this.sets; index2 += 1) {
          if (index === this.frequency - 1) {
            decriment = 0;
          } else {
            decriment += (index2 % (2 + index) ? 0 : 1);
          }
          tempRepArray.push(this.reps - decriment);
        }
        repArray.push(tempRepArray);
      }
      return repArray;
    },
    repWeightIncrements() {
      const repWeightArray = [];
      for (let index = 0; index < this.weeks; index += 1) {
        repWeightArray.push(parseInt(this.weightUsed, 10)
        + (index * parseInt(this.weeklyIncrement, 10)));
      }
      return repWeightArray;
    },
    weightUsed() {
      return this.repWeight ? this.repWeight : this.repWeightFromMaxPR;
    },
    repWeightFromMaxPR() {
      const unrounded = this.maxPR * ((100 - (4 * this.reps)) / 100);
      return Math.round(unrounded / 5) * 5;
    },
    warmupRepScheme() {
      const repArray = [];
      const percentArray = [0.6, 0.75, 0.9];
      for (let index = 0; index < this.warmupSets; index += 1) {
        const unrounded = parseInt(this.weightUsed, 10) * percentArray[index];
        repArray.push(Math.round(unrounded / 5) * 5);
      }
      return repArray;
    },
  },
};
</script>
